body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  min-height: 70px !important;
}

.brand-logo {
  left: 0px !important;
  transform: none !important;
  margin-left: 4rem;
}

.logoValmexFull {
  max-height: 50px !important;
  margin-top: 0.7rem;
}

.logoValmexMobile {
  max-height: 50px !important;
  margin-top: 0.7rem;
}

.optSide {
  border-bottom: #0000002e solid 1px !important;
}
.logoSide {
  max-height: 100px !important;
}

.boldText {
  font-weight: 600 !important;
}

nav .nav-wrapper {
  border-bottom: #41bbc9 solid 0.5px !important;
}

.sideNavBtn {
  margin: 0 0 !important;
}

.valmexContainer {
  border-top: black solid 0.5px;
  margin-top: 2px;
}
.sideNavBtn:hover {
  background-color: white;
}

.z-depth-1,
nav,
.card-panel,
.toast,
.btn,
.btn-large,
.btn-small,
.btn-floating,
.dropdown-content,
.collapsible,
.sidenav {
  box-shadow: none !important;
}

@media only screen and (min-width: 993px) {
  .sideNavBtn {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  #modal_token {
    top: 0% !important;
    width: 100% !important;
    max-height: 100% !important;
    height: 100vh;
  }
}

@media (max-width: 800px) {
  .logoValmexFull {
    display: none;
  }

  .logoValmexMobile {
    display: block;
  }

  .modal{
    top: 0% !important;
    width: 100% !important;
    max-height: 100% !important;
    height: 100vh;
  }

  .modal.modal-fixed-footer {
    padding: 0;
    height: 100% !important;
}
}
@media (min-width: 601px) {
  .logoValmexFull {
    display: block;
  }

  .logoValmexMobile {
    display: none;
  }
}

/*Colores brandBook*/

.navyBackground {
  background-color: #202a52;
}

.greyBackground {
  background-color: #d0d0d0;
}

.whiteBackground {
  background-color: white;
}

.loginContainer {
  min-height: 95vh !important;
}

.loginContainer form {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  max-width: 380px;
}

.fondoImagenLogin{
  background: url('./assets/icons/fotoFondo.png') no-repeat center center !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh !important;

}

.newFormLogin{
  position: absolute !important;
  top: 6rem !important;
  /* background: white; */
  max-width: 357px !important;
  margin-left: 4rem !important;
  border-radius: 8px !important;
  border: 1px solid var(--VLMX_Blackie_20, #CCC) !important;
  background: var(--White, #FFF) !important;
}

.unlockContainer {
  min-height: 95vh !important;
}

.unlockContainer form {
  background-color: white;
  padding: 10px;
}

.panding-5 {
  padding: 5px !important;
}

.blockUnlock {
  max-width: 412px !important;
}

.input-field input[type="text"]:focus + label,
input[type="password"]:focus + label {
  color: #000 !important;
}

.input-field input[type="text"]:focus,
input[type="password"]:focus {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}

.btn {
  border-radius: 50px !important;
}

.primaryBtn {
  min-width: 200px !important;
  text-transform: none !important;
  background: rgb(179, 237, 244) !important;
  background: linear-gradient(
    94deg,
    rgba(179, 237, 244, 1) 0%,
    rgba(65, 187, 201, 1) 68%,
    rgba(51, 161, 173, 1) 100%
  ) !important;
}

.primaryBtn_oc {
  text-transform: none !important;
  background: rgb(179, 237, 244) !important;
  background: linear-gradient(
    94deg,
    rgba(179, 237, 244, 1) 0%,
    rgba(65, 187, 201, 1) 68%,
    rgba(51, 161, 173, 1) 100%
  ) !important;
}

.flatPrimary {
  border-radius: 24px;
  border: solid 0.5px;
  font-weight: bold;
  text-transform: none;
  color: #41bbc9;
  text-align: center !important;
}

/*Margenes*/
.mt-m5 {
  margin-top: 0.5rem !important;
}

.mt-m1_5 {
  margin-top: 1.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mt-8 {
  margin-top: 9rem !important;
}
.mt-9 {
  margin-top: 9rem !important;
}
.mt-10px {
  margin-top: 10px;
  min-height: 400px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-14 {
  margin-left: 14px;
}

.mtless-10 {
  margin-top: -10px;
}

.mtless-8 {
  margin-top: -8px;
}

.maxh-100 {
  max-height: 100px;
}

.mb-0px {
  margin-bottom: 0px;
}

.mt-0px {
  margin-top: 0px ;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mr-1 {
  margin-right: 1rem !important;
}
.pt-1r {
  padding-top: 1rem !important;
}

.pb-1r {
  padding-bottom: 1rem !important;
}

.pt-13 {
  padding-top: 13px;
}

.pt-10 {
  padding-top: 10px !important;
}

.brandTextPrimary {
  color: #41bbc9;
}

.brandTextPrimaryUnderline {
  color: #41bbc9;
  text-decoration: underline !important;
}

.brandTextPrimaryUnderlineDisabled {
  color: gray;
  text-decoration: underline !important;
}

.visibilityEyes {
  position: absolute !important;
  right: 10px !important;
  top: 20px !important;
  cursor: pointer !important;
}

/*NAV*/
/* #slide-menu{
  top: 73px !important;
} */

/* .modal .modal-footer {
  border-top: solid 0.5px #dad6d6bf !important;
  text-align: center !important;
} */

.width100 {
  width: 100%;
}

.closeModalOk {
  /* float: right;
  border: solid 1px;
  border-radius: 50%;
  width: 35px; */
  width: 100%;
  background: #202a52;
  color: white;
  min-height: 50px;
}

.closeModalOk .material-icons {
  
  margin-left: 10px;
  font-size: 25px !important;
  margin-top: 10px !important;
}

.tokenInputs {
  max-width: 400px;
}

.cursorPointer {
  cursor: pointer;
}

.cursorHelp {
  cursor: help;
}

.helpIcon {
  position: absolute;
  font-size: 16px;
  margin-top: 4px;
  margin-left: 5px;
}

.iconF {
  font-size: 20px !important;
  margin-top: 3px !important;
}

.noMargin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.noMBottom {
  margin-bottom: 0px !important;
}

.noMTop {
  margin-top: 0px !important;
}

/*Custom NAV*/
.sidenav li > a > i,
.sidenav li > a > [class^="mdi-"],
.sidenav li > a li > a > [class*="mdi-"],
.sidenav li > a > i.material-icons {
  margin: 0 10px 0 0 !important;
}

.sidenav li > a {
  padding: 0 10px !important;
}


.sidenavUser{
  max-width: 500px !important;
    min-width: 350px !important;
}
/*SALDO LIQUIDO*/

.posicionCartera {
  margin: 10px;
  border-radius: 10px;
}

/*CUSTOM ARROW*/
.mh-100 {
  min-height: 100px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.colorIndicator {
  border-radius: 20px;
  max-width: 10px;
  height: 100%;
}

.rendimientoTotal {
  border-radius: 20px;
  margin-top: 10px;
}

.percentRendimientoTotal {
  width: auto;
  border: solid 0.5px #dedddd;
  padding: 10px;
  font-size: 30px;
  border-radius: 10px;
}

@media only screen and (min-width: 993px) {
  .paddingOperaciones {
    padding-right: 20px;
  }
}

.br20 {
  border-radius: 20px;
}

.icn-spinner {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.sName {
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: 20px !important;
}

.contratoSelect {
  line-height: 40px !important;
  color: #9e9e9e;
  font-size: 17px;
}

.avatarContainer {
  max-width: 330px !important;
}

.avatarContainer a:hover {
  background-color: rgb(0 0 0 / 0%) !important;
}

.avatar {
  border: solid #9e9e9e 0.5px !important;
  margin-top: 3px !important;
  max-height: 200px !important;
}

.avatarPerfil {
  height: 130px !important;
  width: 130px !important;
  display: inline !important;
}

.avatarPerfilN {
  height: 100% !important;
  width: 80% !important;
  display: inline !important;
}

.avatarN {
  max-height: 200px !important;
}

.avatarNav{
  margin-left: -2.5rem !important;
  height: 50px !important;
  margin-top: 4px;
}

.bgPerfil {
  background: rgb(62, 75, 129) !important;
  background: linear-gradient(
    94deg,
    rgba(62, 75, 129, 1) 0%,
    rgba(42, 53, 99, 1) 27%,
    rgba(32, 42, 82, 1) 69%
  ) !important;
  line-height: 80px !important;
}

.bgOption {
  background: rgb(62, 75, 129) !important;
  background: linear-gradient(
    74deg,
    rgba(62, 75, 129, 1) 0%,
    rgba(42, 53, 99, 1) 27%,
    rgba(32, 42, 82, 1) 69%
  ) !important;
}

.customIcon {
  margin: 13px 10px 0 10px !important;
}

.contratos-ln {
  line-height: 5px !important;
}

.ln-h30 {
  line-height: 30px !important;
}

.ln-h20 {
  line-height: 20px !important;
}

.contratosContainer {
  border: solid 1px #dcdcdc;
  border-radius: 10px;
  margin: 10px;
}

.contratoSelected {
  border: solid 1px #e2dede40;
  border-radius: 10px;
  margin: 10px;
  background-color: #e2dede40;
  border-bottom: solid 1px #e2dede;
}

.infoSaldo {
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 10px;
}

.contratoNoSelected {
  margin: 10px;
  border-bottom: solid 1px #e2dede;
}

.NavyPrimary {
  color: #202a52;
}

.txtOption {
  color: white !important;
  font-size: 20px !important;
}

.loader p {
  color: black !important;
}

#modal_okButton {
  max-width: 400px !important;
}

#modal_okButton .center-align {
  text-align: center !important;
}

.headerContent {
  height: 50px;
  padding-top: 13px !important;
}

.conditionPass {
  font-size: 10px;
}

.conditionPass i {
  font-size: 12px;
  margin-top: 2px;
}

.conditionPass.invalid {
  color: red;
}

.conditionPass.valid {
  color: green;
}

.validationList {
  border: solid #e0e0e0 1.5px !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 10px !important;
}

.optRowContainer {
  padding-left: 2rem;
  padding-right: 2rem;
}
.optContainer {
  border: solid 0.5px #dad6d6bf !important;
  border-radius: 10px;
  cursor: pointer;
}

.optOperacion {
  min-width: 50px;
}

.optOperacionTxt {
  color: gray;
}

.borderRight {
  border-right: solid 1px #d2d4dc;
}

.bgGray {
  background-color: #f4f4f6;
}

.txtGray {
  color: #f4f4f6;
}

.divisaUpDown {
  font-size: 12px;
}

.divisasContainer {
  max-height: 400px !important;
  overflow: auto !important;
}

.divisasContainer .col {
  min-height: 30px;
  padding-top: 5px;
  font-size: 15px;
}

.redTxt {
  color: #a21f1f;
}

.greenTxt {
  color: #13bb13;
}

.indicesContainer .collapsible-body {
  padding-top: 0;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 0;
}

.floatRight {
  float: right !important;
}

.floatLeft {
  float: left !important;
}

.grayBorder {
  border: solid 1px gray;
  border-radius: 10px;
}

.paddingCero {
  padding: 0 !important;
}

.paddingRightCero {
  padding-right: 0 !important;
}

.paddingRigth20 {
  padding-right: 20px !important;
}

.paddingLeftCero {
  padding-left: 0 !important;
}

.pl-20{
  padding-left:20px !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.descPerfil {
  line-height: 20px !important;
  text-align: justify !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.bgSky {
  background-color: #41bbc9 !important;
}

.bgSub{
  background-color: #dbf5f9 !important;
}

.subText{
  color:#12C3E2 !important;
}

.bgSky.SaldoLiquido {
  height: 35px !important;
  padding-top: 5px !important;
}

.pAbsolute {
  position: absolute !important;
  margin-left: 10px;
}

.txtSky {
  color: #41bbc9 !important;
}

.titleModal {
  position: absolute !important;
  margin-top: 0.5rem !important;
  font-size: 21px !important;
  margin-left: 10px !important;
}


.title-n {
  margin-top: 0.5rem !important;
  font-size: 21px !important;
}

.w-90 {
  width: 90%;
}

.w-10 {
  width: 10%;
}

.w-159p{
  width: 159px !important;
}

.w-292p{
  width: 292px !important;
}


.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.contactoContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.optCollPer {
  position: absolute !important;
  right: 0 !important;
}

/**/

.tabs .tab a:hover,
.tabs .tab a.active {
  background-color: #41bbc917 !important;
  color: #41bbc9 !important;
  border-bottom: solid 1px #41bbc9;
}

.tabs .tab a {
  color: black !important;
  border-bottom: solid 0.5px;
}

.tabs .indicator {
  background-color: transparent !important;
}

.ft-20 {
  font-size: 20px;
}

.ft-24 {
  font-size: 24px;
}

.ft-12 {
  font-size: 12px !important;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-15 {
  font-size: 15px !important;
}


.ft-16 {
  font-size: 16px !important;
}

.ft-10 {
  font-size: 10px !important;
}

.fw-400{
  font-weight: 400 !important;
}

.fw-300{
  font-weight: 300 !important;
}

.titleFondos {
  height: 50px;
  padding-top: 0.5rem !important;
}

.infoIRow {
  border-top: solid 0.5px #80808054 !important;
  border-bottom: solid 0.5px #80808054 !important;
}

.infoIRow:hover,
.infoIRow.active {
  background-color: #41bbc917 !important;
  color: #41bbc9 !important;
  border-bottom: solid 1px #41bbc9;
  cursor: pointer;
}

.notificacionContainer {
  border: solid 0.5px #8080809c !important;
  border-radius: 10px !important;
}

.preguntaContainer {
  border: solid 0.5px #41BBC9 !important;
  border-radius: 10px !important;
}

.contentInfoFondo {
  margin-left: 2rem !important;
  max-width: 500px !important;
}

.ml-1-4 {
  margin-left: 1.4rem !important;
}

.ml-1-5 {
  margin-left: 1.5rem !important;
}

.rightShadow {
  -moz-box-shadow: 4px 0px 4px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 4px 0px 4px rgba(68, 68, 68, 0.6);
  box-shadow: 4px 0px 4px rgba(68, 68, 68, 0.6);
}

/*Notificaciones*/
.ntitleContent {
  height: 50px;
  padding-top: 0.5rem !important;
}

.activeNot {
  border-color: #41bbc9 !important;
  background: #f2f2f2 !important;
}

.borderInfoFT {
  border-top: solid 1px #8080807d;
}

.borderInfoF {
  border-bottom: solid 1px #8080807d;
}

.paddingTopBI {
  padding-top: 5px;
}

.minusMT-9 {
  margin-top: -9px !important;
}

.contentInfoFondos {
  height: 100vh;
  overflow: auto;
}

.contentNotifications {
  height: 85vh;
  overflow: auto;
}

.boxDepositoRetiro {
  background: #ffffff;
  /* VLMX_Blackie_20 */

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
}

.marginLR-1r {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.cuentaSelected {
  background: #f4f4f6 !important;
  border-radius: 8px !important;
}

.containerDetalleOpera {
  background: #ffffff;
  /* VLMX_Navy_20 */

  border: 1px solid #d2d4dc;
  box-sizing: border-box;
  border-radius: 8px;
}

.maxRetiro {
  border-top: solid 1px #d2d4dc;
  border-bottom: solid 1px #d2d4dc;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.fR {
  float: right !important;
}

.cargoAbonoIcon {
  position: absolute !important;
  margin-top: -4px !important;
}

.borderBFiltro {
  border-bottom: solid 1px #d2d4dc;
  border-left: solid 1px #d2d4dc;
}

.borderTopDocs {
  border-top: solid 1px #d2d4dc !important;
}

.borderBottomDocs {
  border-bottom: solid 1px #d2d4dc !important;
}

.borderGray {
  border: solid 1px #d2d4dc !important;
}

.borderLeftFiltro {
  border-left: solid 1px #d2d4dc;
}

.contentMov {
  border-bottom: solid 1px #d2d4dc;
  border-right: solid 1px #d2d4dc;
  margin-bottom: 0px !important;
}

.contentLisMov {
  height: 603px !important;
  overflow: auto !important;
  border-bottom: solid 1px #d2d4dc;
}

.iconFilter {
  float: right !important;
  margin-right: 10px !important;
  margin-top: 0.9rem !important;
  cursor: pointer !important;
}

.documentos_modal {
  min-width: 80% !important;
}

.historicodocs_modal {
  min-width: 80% !important;
}

.heightContent {
  height: 500px !important;
  overflow: auto !important;
}

.txtCap {
  text-transform: capitalize !important;
}

.emiComp {
  margin-top: 2px !important;
  position: absolute !important;
  margin-left: 5px !important;
}

.txtBlackie {
  color: #999999 !important ;
}

.contentBarChart {
  border: solid 0.5px !important;
  border-radius: 10px !important;
  min-height: 465px !important;
  border-color: #cccccc !important;
}

.headerHistorico {
  padding-top: 5px !important;
  font-size: 12px;
}

.align-justify {
  text-align: justify !important;
}

.noMarginR {
  margin-right: 0rem !important;
}
.cpadding {
  padding: 0 4px !important;
  font-size: 13px !important;
}

.contentLegal {
  white-space: pre-wrap !important;
  max-width: 80%;
}

.contentFaq {
  white-space: pre-wrap !important;
}

.mapaClass {
  height: 300px;
}

.noEventPointer {
  pointer-events: none;
}

.hoverContent:hover {
  background: #f2f2f2 !important;
}

.disableItemList {
  background: #f2f2f2 !important;
  color: #000000a1 !important;
}

.modal.modal-fixed-header {
  padding: 0;
  height: 70%;
}
.modal.modal-fixed-header .modal-header {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
}
.modal.modal-fixed-header .modal-header {
  border-radius: 0 0 2px 2px;
  padding: 4px 6px;
  height: 56px;
  width: 100%;
}
.modal.modal-fixed-header .modal-content {
  position: absolute;
  top: 56px;
  height: calc(44%);
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal.modal-fixed-footer .modal-content {
  height: 100% !important;
  padding-top: 3.6rem !important;
}

.modal.modal-fixed-footer .modal-footer {
  border-top: 0px !important;
  bottom: unset !important;
}

.modal .modal-footer {
  padding: 0px !important;
}

.modal .modal-content {
  padding: 24px 24px 0px 24px !important;
}

.link {
  text-decoration: underline;
  color: blue;
}

.txtUnderline{
  text-decoration: underline !important;
}

.modalRecarga {
  box-shadow: none !important;
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  top: 0% !important;
  background: white !important;
}


.pdfviewer_modal{
  width: 100% !important;
  top: 0% !important;
  height: 100vh !important;
  max-height: 100% !important;
}

.pdfviewer_modal .modal-content{
 padding: 0px !important;
}

.pdf-viewer{
  width: 100% !important;
  height: 85vh !important;
}

.tablaDivisas, th {
  padding: 6px !important;
}

.tablaDivisas, td {
  padding: 6px !important;
}

.tablaDivisas, tr {
  border-bottom: none !important;
}

.contentNotification{
  height: 100% !important;
  overflow-y: auto !important;
}

.react-datepicker-wrapper{
  max-width: 95% !important;
  float: left !important;
  margin-left: -0.7rem !important;
}

.modalInfo{
  max-width: 300px !important;
  max-height: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  top: 6rem !important;
}

.modalFchNac{
  max-width: 350px !important;
  max-height: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  top: 6rem !important;
}

.modalInfoCall{
  max-width: 350px !important;
  max-height: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  top: 6rem !important;
}

/* .inputTokenContainer{
  padding-left: 16rem !important;
  padding-right: 16rem !important;
} */

/* @media only screen and (max-width: 700px){
  .inputTokenContainer{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
}


@media only screen and (max-width: 500px){
  .inputTokenContainer{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media only screen and (max-width: 321px){
  .inputTokenContainer{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
} */


@media only screen and (min-width: 1000px) and (max-width: 1612px ) {
  #detalleRecibo{
    width: 85% !important;
    padding-left: 10% !important;
  }
}

@media only screen and (min-width: 1613px){
  #detalleRecibo{
    width: 80% !important;
    padding-left: 10% !important;

  }
}

@media only screen and (max-width: 992px){
  .avatarNav {
    margin-left: -4.5rem !important;
  }
}

@media only screen and (max-width: 435px){
  .newFormLogin{
   
    margin-left: 2rem !important;
  
  }
}


#model_operacion{
  min-width: 45% !important;
}


#model_operacion.validaciondatosactivacion_modal{
  max-width: 412px !important;
  min-width: 412px !important;
  min-height: 400px !important;
}

#model_operacion.establececontrasenia_modal{
  max-width: 412px !important;
  min-width: 412px !important;
  min-height: 400px !important;
}



#model_operacion.tokenactivacion_modal{
  max-width: 412px !important;
  min-width: 412px !important;
  min-height: 400px !important;
}

#model_operacion.successActivacion_modal{
  max-width: 412px !important;
  min-width: 412px !important;
  min-height: 400px !important;
}

#model_operacion.successLoginWeb_modal{
  max-width: 412px !important;
  min-width: 412px !important;
  min-height: 400px !important;
}


.btnscs{
    position: absolute !important;
    background-color: #00205C !important;
    color: #FFFFFF !important;
    font-size: 8px !important;
    text-transform: none !important;
    top: 10px !important;
    right: 10px !important;
}

#sidenavNoSesion{
  min-width: 340px !important;
}
