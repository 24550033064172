.App {
  text-align: center;
}


.f-r{
  float: right !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.texto_resaltado{
  font-weight: 400;
  font-size: 16px;
}

.no-plpr{
  padding-right: 0px !important;
    padding-left: 0px !important;
}

.tituloPregunta{
  font-weight: 400;
  font-size: 20px;
  color:  #202A52;
}

.descripcionPregunta{
  color: #333333 !important;
  font-weight: 300 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.titleTips{
  font-size: 24px !important;
  font-weight: 400 !important;
}

.tipTxt{
  margin-top: 1rem !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.titleNot{
  color: #41BBC9 !important;
  font-size: 16px;
  font-weight: 400 !important;
}

.blueText{
  color: #36478A !important;
}

.blueTextBold{
  color: #202A52;
  font-weight: bold !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentEstimacion{
  padding-bottom: 10px !important;
  border-radius: 8px !important;
  margin-top: 1rem !important;
  border: 1px solid #D2D4DC !important;
  background: #F8F8F8 !important;
}


@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";